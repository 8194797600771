
























































































































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent } from '@/mixins/report-chart-base';
import ParkingIndexService from './service/parking-index';
import { ParkingTrafficFlowRankingQueryModel } from './model/parking-traffic-flow-ranking-model';
import { ParkingGateType } from '@/model/enum';
import { percentFormat } from '@/filter';
import { ParkingTrafficFlowQueryModel } from './model/parking-traffic-flow-model';

@Component
export default class ParkingIndexComponent extends Mixins(ReportChartRingComponent, ReportChartLineComponent, ReportChartHistogramComponent) {
    ParkingGateType = ParkingGateType;
    percentFormat = percentFormat;
    headerCardList: Array<any> = [
        { title: '车位总数', count: null, unit: '个', loading: true, icon: '/img/parking.png' },
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device.png' },
        { title: '今日累计驶入车次', count: null, unit: '次', loading: true, icon: '/img/in-total.png' },
        { title: '异常设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' },
        { title: '未处理事件', count: null, unit: '条', loading: true, icon: '/img/alarm-unhandle.png' }
    ];
    parkingSpaceData: any = {};
    parkingSpaceDataLoading: boolean = true;

    parkingGateData: any = {};
    parkingGateDataLoading: boolean = true;

    parkingGateRankData: any = null;
    parkingGateRankDataLoading: boolean = true;

    trafficFlowData: any = null;
    trafficFlowDataLoading: boolean = true;

    ruleEventData: any = null;

    created() {
        this.initData();
    }

    initData() {
        ParkingIndexService.getFacilityCount().then(res => {
            this.headerCardList[1].count = res.totalCount;
            this.headerCardList[3].count = res.faultCount;
        }).finally(() => {
            this.headerCardList[1].loading = false;
            this.headerCardList[3].loading = false;
        });
        ParkingIndexService.getTrafficFlowCount().then(res => {
            this.headerCardList[2].count = res.totalCount;
        }).finally(() => this.headerCardList[2].loading = false);

        ParkingIndexService.getParkingSpaceUsage().then(res => {
            this.headerCardList[0].count = res.totalCount;
            this.parkingSpaceData = res;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.parkingSpaceDataLoading = false;
        });

        ParkingIndexService.getTrafficFlow(new ParkingTrafficFlowQueryModel()).then(res => {
            this.trafficFlowData = res;
        }).finally(() => this.trafficFlowDataLoading = false);

        ParkingIndexService.getParkingGate().then(res => {
            this.parkingGateData = res;
        }).finally(() => this.parkingGateDataLoading = false);

        ParkingIndexService.getParkingGateRank(new ParkingTrafficFlowRankingQueryModel(5)).then(res => {
            this.parkingGateRankData = res;
        }).finally(() => this.parkingGateRankDataLoading = false);

        ParkingIndexService.getRuleEventCount().then(res => {
            this.ruleEventData = res;
            this.headerCardList[4].count = _.get(_.find(res.rows, item => item.name === '未处理'), 'count');
        }).finally(() => this.headerCardList[4].loading = false);
    }

    get TrafficFlowSettings() {
        return {
            type: 'line',
            area: true,
            // yAxisType: ['KMB'],
            yAxisName: ['车次'],
            labelMap: { inCount: '驶入', outCount: '驶出' }
        };
    }

    get ParkingGateChartData() {
        return {
            columns: ['name', 'count'],
            rows: [
                { name: '出口', count: _.get(this.parkingGateData, 'exitCount', 0) },
                { name: '入口', count: _.get(this.parkingGateData, 'entranceCount', 0) }
            ]
        };
    }
    get ChartRingData() {
        return {
            columns: ['name', 'count'],
            rows: [
                { name: '出口', 'count': 1281 },
                { name: '入口', 'count': 645 }
            ]
        };
    }

    get FacilityChartData() {
        const facilityFaultCount = _.get(this.headerCardList, '[3].count') || 0;
        const facilityNormalCount = (_.get(this.headerCardList, '[1].count') || 0) - facilityFaultCount;
        return {
            columns: ['name', 'count'],
            rows: [
                { name: '正常', count: facilityNormalCount },
                { name: '异常', count: facilityFaultCount }
            ]
        };
    }

    async trafficFlowChange(ev: any) {
        const qModel = new ParkingTrafficFlowRankingQueryModel(5);
        qModel.type = _.get(ev, 'target.value');
        ParkingIndexService.getParkingGateRank(qModel).then(res => {
            this.parkingGateRankData = res;
        });
    }
}
