var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parking-index-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/parking-index" } },
                    [_vm._v("停车场管理")]
                  )
                ],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("概览")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "div",
          { staticClass: "parking-card-wrapper" },
          [
            _c(
              "a-row",
              {
                staticClass: "sub-system-header-card",
                attrs: {
                  type: "flex",
                  justify: "space-around",
                  align: "middle"
                }
              },
              _vm._l(_vm.headerCardList, function(item, index) {
                return _c(
                  "a-col",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: item.loading,
                        expression: "item.loading"
                      }
                    ],
                    key: index,
                    staticClass: "parking-card-item text-center"
                  },
                  [
                    _c("img", {
                      staticClass: "parking-card-item-icon",
                      attrs: { src: item.icon }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "parking-card-item-text-wrapper text-left"
                      },
                      [
                        _c("span", { staticClass: "parking-card-item-title" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "parking-card-item-number" },
                          [_vm._v(_vm._s(_vm._f("text")(item.count)))]
                        ),
                        _c("span", { staticClass: "parking-card-item-unit" }, [
                          _vm._v(_vm._s(item.unit))
                        ])
                      ]
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.parkingSpaceDataLoading,
                        expression: "parkingSpaceDataLoading"
                      }
                    ],
                    staticClass: "parking-gauge-chart",
                    attrs: { title: "车位使用实时统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/rate"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "a-row",
                      {
                        staticClass: "parking-gauge-chart-content",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          justify: "center"
                        }
                      },
                      [
                        _c(
                          "a-col",
                          { staticClass: "text-center", attrs: { span: 10 } },
                          [
                            _c("jtl-custom-gauge", {
                              staticClass: "inline-block",
                              attrs: {
                                width: 160,
                                "stroke-width": 15,
                                percent: _vm.parkingSpaceData.rate,
                                "percent-desc": "停车率"
                              }
                            }),
                            _c(
                              "a-row",
                              {
                                staticClass: "parking-gauge-chart-text-wrapper",
                                attrs: { type: "flex", align: "middle" }
                              },
                              [
                                _c("a-col", { attrs: { span: 8 } }, [
                                  _c("span", { staticClass: "parking-title" }, [
                                    _vm._v("全部")
                                  ])
                                ]),
                                _c(
                                  "a-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c(
                                      "a-row",
                                      [
                                        _c(
                                          "a-col",
                                          {
                                            staticClass: "parking-desc",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("车位总数")]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            staticClass:
                                              "parking-count text-right",
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("text")(
                                                  _vm.parkingSpaceData
                                                    .totalCount
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-row",
                                      [
                                        _c(
                                          "a-col",
                                          {
                                            staticClass: "parking-desc",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("剩余车位")]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            staticClass:
                                              "parking-count text-right",
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("text")(
                                                  _vm.parkingSpaceData.freeCount
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-table",
                              {
                                staticClass: "parking-table",
                                attrs: {
                                  "children-column-name": "noChildren",
                                  "data-source": _vm.parkingSpaceData.children,
                                  pagination: false,
                                  bordered: false,
                                  scroll: { y: 300 },
                                  size: "small"
                                }
                              },
                              [
                                _c("a-table-column", {
                                  key: "locationName",
                                  attrs: {
                                    title: "区域",
                                    "data-index": "locationName"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "totalCount",
                                  attrs: {
                                    title: "车位总数",
                                    "data-index": "totalCount"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "free",
                                  attrs: {
                                    title: "剩余车位",
                                    "data-index": "freeCount"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "rate",
                                  attrs: {
                                    title: "停车率",
                                    "data-index": "rate",
                                    "custom-render": function(text) {
                                      return _vm.percentFormat(text)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.trafficFlowDataLoading,
                        expression: "trafficFlowDataLoading"
                      }
                    ],
                    attrs: { title: "今天车流量统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/traffic-flow"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-line", {
                      attrs: {
                        data: _vm.trafficFlowData,
                        settings: _vm.TrafficFlowSettings,
                        extend: _vm.ChartLineExtend
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { gutter: [16, 32] } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.parkingGateDataLoading,
                        expression: "parkingGateDataLoading"
                      }
                    ],
                    attrs: { title: "出入口统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-entrance-exit-list"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "a-row",
                      {
                        staticClass: "parking-gauge-chart-content",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          justify: "center"
                        }
                      },
                      [
                        _c(
                          "a-col",
                          { staticClass: "text-center", attrs: { span: 12 } },
                          [
                            _c("jtl-ring", {
                              attrs: {
                                data: _vm.ParkingGateChartData,
                                settings: _vm.ChartRingSettings,
                                extend: _vm.ChartRingExtend,
                                "legend-visible": false
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "a-table",
                              {
                                staticClass: "parking-table",
                                attrs: {
                                  "data-source": _vm.parkingGateData.children,
                                  pagination: false,
                                  bordered: false,
                                  scroll: { y: 300 },
                                  size: "small"
                                }
                              },
                              [
                                _c("a-table-column", {
                                  key: "locationName",
                                  attrs: {
                                    title: "区域",
                                    "data-index": "locationName"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "entranceCount",
                                  attrs: {
                                    title: "入口",
                                    "data-index": "entranceCount"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "exitCount",
                                  attrs: {
                                    title: "出口",
                                    "data-index": "exitCount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.headerCardList[4].loading,
                        expression: "headerCardList[4].loading"
                      }
                    ],
                    attrs: { title: "今天告警状态统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-event"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-ring", {
                      attrs: {
                        data: _vm.ruleEventData,
                        settings: _vm.ChartRingSettings,
                        extend: _vm.ChartRingExtend,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.parkingGateRankDataLoading,
                        expression: "parkingGateRankDataLoading"
                      }
                    ],
                    attrs: { title: "今天出入口流量排名" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/traffic-flow-ranking"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _vm.parkingGateRankData
                      ? _c("jtl-bar", {
                          attrs: {
                            data: _vm.parkingGateRankData.data,
                            settings: _vm.parkingGateRankData.settings,
                            extend: _vm.parkingGateRankData.extend,
                            "y-axis": _vm.parkingGateRankData.yAxis,
                            "x-axis": _vm.parkingGateRankData.xAxis,
                            "legend-visible": false
                          }
                        })
                      : _vm._e(),
                    _c(
                      "a-radio-group",
                      {
                        ref: "trafficFlow",
                        staticClass:
                          "in-out-switch h-center jtl-chart-radio-button",
                        attrs: {
                          "default-value": _vm.ParkingGateType.EXIT,
                          "button-style": "solid"
                        },
                        on: { change: _vm.trafficFlowChange }
                      },
                      [
                        _c(
                          "a-radio-button",
                          { attrs: { value: _vm.ParkingGateType.ENTRANCE } },
                          [_vm._v(" 入口 ")]
                        ),
                        _c(
                          "a-radio-button",
                          { attrs: { value: _vm.ParkingGateType.EXIT } },
                          [_vm._v(" 出口 ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.headerCardList[1].loading,
                        expression: "headerCardList[1].loading"
                      }
                    ],
                    attrs: { title: "设备状态统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-facility-list"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-ring", {
                      attrs: {
                        data: _vm.FacilityChartData,
                        settings: _vm.ChartRingSettings,
                        extend: _vm.ChartRingExtend,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }